import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Seo from "./seo";
import Nav from "./nav";
import Footer from "./footer";
import Banner from "./banner";
import CookieBanner from "./cookie_banner";
import CookieConsent, { Cookies } from "react-cookie-consent";
import {Helmet} from "react-helmet";

const Layout = ({ children, seo }) => (
  <StaticQuery
    query={graphql`
      query {
        strapiHomepage {
          seo {
            metaTitle
            metaDescription
            shareImage {
              publicURL
            }
          }
        }
      }
    `}
    render={(data) => (
      <div className="font-sans antialiased text-gray-900">
        <Seo seo={seo} />
        <Nav />
        {children}
        <Footer />
        <Banner />
        <CookieBanner />
      </div>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
