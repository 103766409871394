/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import CookieConsent from "react-cookie-consent";

export default function Example() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      containerClasses="p-2 bg-indigo-500-cookie"
      disableButtonStyles="true"
      buttonWrapperClasses="my-auto"
      buttonClasses="flex items-center justify-center mr-32 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50"
      cookieName="gatsby-gdpr-google-analytics"
    >
      <p>This website uses cookies to improve your website experience and provide
        more personalized services to you.</p><p> To find out more about the cookies we
      use, see our{" "}
      <a href="/privacy/" className="underline">
        Privacy Policy
      </a>
      .</p>
    </CookieConsent>
  );
}
